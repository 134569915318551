@import "src/styles/variables";

span.productsRollup {
  display: none;
  position: relative;
  width: max-content;
  padding: 8px 0;
  margin-bottom: 8px;
  color: $primary-color;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: calc(100% + 9px);
    width: 9px;
    height: 9px;
    background: url('../../../images/rollup.svg') center / contain no-repeat;
    transition: all .3s;
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: block;
  }
}

.productExpand {
  &:after {
    transform: rotate(180deg);
  }
}

.showProducts {
  max-height: 2000px;
  visibility: visible;
  opacity: 1;
  transition: all .5s ease;
}

.hideProducts {
  transition: all .1s ease;

  @media (max-width: map_get($grid-breakpoints, xl)) {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
  }
}