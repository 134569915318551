@import "./src/styles/variables";

.content {
  background: $background-color;
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px 24px;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    padding: 0;
  }
}