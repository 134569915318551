@import "src/styles/variables";

@mixin borderLine {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: $notification-new-color;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    width: 100%;
  }
}

.borderTopLine {
  position: relative;

  &:before {
    @include borderLine;
    top: 0;
  }
}

.borderBottomLine {
  position: relative;

  &:after {
    @include borderLine;
    bottom: 0;
  }
}

div.borderBothLine {
  position: relative;

  &:before {
    @include borderLine;
    top: 0;
  }

  &:last-of-type {
    &:after {
      @include borderLine;
      bottom: 0;
    }
  }
}

.productLinePadding {
  padding-top: 12px;
  padding-bottom: 12px;
}