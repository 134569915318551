@import "src/styles/variables";

.contentBlock {
  max-width: 944px;
  padding: 24px 32px;
  margin: 0 auto 32px auto;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    max-width: 100%;
    padding: 24px 16px;
    border-radius: 0;
  }
}