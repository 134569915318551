@import "src/styles/variables";
@import "status";

@mixin borderLine {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: $notification-new-color;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    width: 100%;
  }
}

span.badgeStatus {
  width: max-content;
  color: white;
  padding: 4px 8px;
  cursor: default;
}

.badgeStatusRegistration {
  background-color: $registration;
}

.badgeStatusProcessing {
  background-color: $processing;
}

.badgeStatusProduction {
  background-color: $production;
}
.badgeStatusSupply {
  background-color: $supply;
}

.badgeStatusReady {
  background-color: $ready;
}

.badgeStatusCompleted {
  background-color: $completed;
}

.badgeStatusCanceled {
  background-color: $canceled;
}

.deliveryDescriptionsPaddingLeft {
  padding-left: 32px;
}

.deliveryDetailsPaddingLeft {
  padding-left: 16px;
}

.deliveryIconsImg {
  width: 24px;
  height: auto;
}
div.orderHeaderDateColMobile {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: flex;
    justify-content: space-between;
  }

  & span{
    display: none;
    position: relative;
    top: 4px;
    white-space: nowrap;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      display: block;
    }
  }
}


div.orderProduct {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    justify-content: space-between;
    position: relative;
    margin: 0 !important;
  }
}

div.orderName {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    padding-left: 0 !important;
    margin-right: 0 !important;
    flex: 0 0 100%;
    max-width: 75%;
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    max-width: 65% !important;
  }
}

div.orderQuantity {
  left: 20px;
  white-space: nowrap;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: none;
  }
}

div.orderQuantityMobile {
  display: none;
  width: max-content;
  min-width: max-content;
  max-height: 32px;
  padding: 4px 8px;
  background: $secondary-color;
  border-radius: 100px;
  white-space: nowrap;
  text-align: center;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: block;
  }
}

div.orderPrice {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    text-align: left !important;
    padding-left: 0 !important;
    padding-top: 8px;
    margin-right: 100px;
  }
}

div.orderServiceRow {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    justify-content: space-between;
    margin-right: 0 !important;
  }
}

div.orderService {
  line-height: 16px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding-right: 0;
  }
}

.orderServiceName {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    flex: 0 0 100% !important;
    max-width: 80% !important;
    margin-bottom: 12px;
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    max-width: 72% !important;
  }

  & span {
    position: relative;
    padding-left: 34px;
    margin-bottom: 8px;

    &:before {
      content: '-';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 20px;
      top: 4px;

      @media (max-width: map-get($grid-breakpoints, xl)) {
        display: none;
      }
    }

    @media (max-width: map-get($grid-breakpoints, xl)) {
      padding-left: 0;
    }
  }
}

div.orderServicePrice {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    text-align: left !important;
  }
}

.orderDetails {
  flex: 0 0 46.66667% !important;
  max-width: 46.66667% !important;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 1.5rem;
  }
}

.payButtonMobile {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: 36px;
  }
}

div.borderBothLine {
  position: relative;

  &:before {
    @include borderLine;
    top: 0;
  }

  &:last-of-type {
    &:after {
      @include borderLine;
      bottom: 0;
    }
  }
}
